@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    @apply leading-[normal] m-0;
}
*,
::before,
::after {
    border-width: 0;
}

@font-face {
    font-family: "GoshaSans";
    src: url("../public/fonts/GoshaSans/PPGoshaSans-Regular.eot");
    src: url("../public/fonts/GoshaSans/PPGoshaSans-Regular.woff")
            format("woff"),
        url("../public/fonts/GoshaSans/PPGoshaSans-Regular.otf")
            format("opentype");
}

/* .font-gosha {
    font-family: "GoshaSans" !important;
} */

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.animate-fade-in {
    animation: fadeIn 0.5s ease-out forwards;
}

.scroll-container {
    overflow-y: scroll;
}

/* Optional: Style for the scrollbar */
.scroll-container::-webkit-scrollbar {
    width: 8px;
}
.scroll-container::-webkit-scrollbar-thumb {
    background-color: rgb(229, 229, 229);
}
.scroll-container::-webkit-scrollbar-track {
    background-color: rgb(252, 252, 252);
}

.list-spacing ul > * + * {
    margin-top: 5px;
}

.markdown {
    @apply prose prose-h1:text-sm prose-h1:font-semibold prose-h2:text-sm prose-h2:font-medium prose-h3:text-sm prose-h4:text-sm prose-p:text-sm prose-p:font-normal prose-li:text-sm;
}

.clamp {
    @apply line-clamp-3;
    * {
        display: inline;
    }
    *:not(h1:first-of-type)::after {
        content: "\A";
        white-space: pre;
    }
    *::after {
        content: "\A";
        white-space: pre;
    }
}

*::-webkit-scrollbar-thumb {
    @apply bg-gray-300 hover:bg-gray-400;
    border: 4px solid transparent;
    border-radius: 6px;
    background-clip: padding-box;
}

*::-webkit-scrollbar {
    width: 14px;
}

.no-scrollbar {
    scrollbar-width: none;
    & > div {
        scrollbar-width: none;
    }
}
